import React from "react"
import { StaticImage, getImage} from "gatsby-plugin-image"
import Layout from "../components/layout"

import Testimonials from "../components/testimonials"
import Faqs from "../components/faqs.js"
import { useStaticQuery, graphql, Link } from "gatsby"


const RealEstate = () => { 


return( 

    <Layout>

      <div className = "vr_banner inner">
        <StaticImage
                            src="../images/realstate-header.jpg"
                            quality = "100"
                            layout = "fullWidth"
                            alt="A Gatsby astronaut"
                            placeholder="tracedSVG"
                            />

        <div className = "details container">
        <div className = "details_wrap">

            <h1>Celveland, Ohio Based Full Services Digital Agency </h1>
            <p>
            We help brands win in the competitive digital real estate space
            </p>
            <Link to = "https://api.leadconnectorhq.com/widget/appointment/generalcalendar-estorecompany/discovery-call-for-virtual-assistant">schedule a demo</Link>
        </div>
        </div>


        </div>

<section className = "web_starting">


            <div className="container">

            <h1>Websites starting from <span>$799</span></h1> 
                <h6>Call me just click this link for a DEMO </h6>
                <Link className = "video_schedule" to  = "https://api.leadconnectorhq.com/widget/appointment/generalcalendar-estorecompany/discovery-call-for-virtual-assistant" ><i class="fa fa-video-camera" aria-hidden="true"></i> schedule a demo</Link>
            </div>


</section>


<section className = "real_estate_digital">


    <div className="container">

    <h1>How Can <span>Real Estate Digital Marketing</span> Services Help ?</h1>
    <p>Buyers and sellers are always using keywords to search for the answers to their questions. 55% of buyers actually use social media for
research. Through all the filters and searches, make sure your company is being found and recognized by using our digital marketing
services!</p>

    <ul>
            <li>
            <StaticImage
                            src="../images/1-img.jpg"
                            quality = "100"
                            width = "95"
                            alt="A Gatsby astronaut"
                            placeholder="tracedSVG"
                            />

                            <div>
                                <h2>Real Estate Website Design / Development</h2>

                            <p>Website design is the 1st thing a home buyer search online, it plays a huge role in driving leads. We
have helped many professionals to build great websites. We know how to assemble the right
website for your brand.</p>

                            </div>

            </li>

            <li>
            <StaticImage
                            src="../images/2-img.jpg"
                            quality = "100"
                            width = "95"
                            alt="A Gatsby astronaut"
                            placeholder="tracedSVG"
                            />

                            <div>
                                <h2>Real Estate Email Campaigns</h2>

                            <p>Email campaigns keep your real estate business important in a buyer's mind with steady updates.
Smooth out communication via email every week or month.</p>

                            </div>

            </li>

            <li>
            <StaticImage
                            src="../images/3-img.jpg"
                            quality = "100"
                            width = "95"
                            alt="A Gatsby astronaut"
                            placeholder="tracedSVG"
                            />

                            <div>
                                <h2>Real Estate Social Media Marketing</h2>

                            <p>Social media marketing presents an exceptional chance to connect with your audience. Through
Facebook business ads, our marketing agency can help you to drive more leads.</p>

                            </div>

            </li>

            <li>
            <StaticImage
                            src="../images/4-img.jpg"
                            quality = "100"
                            width = "95"
                            alt="A Gatsby astronaut"
                            placeholder="tracedSVG"
                            />

                            <div>
                                <h2>Search Engine Marketing</h2>

                            <p>SEO allows you to discover your target audience's searching list. We help you to utilize the keywords
the people are searching for and create quality content to generate traffic on your website.</p>

                            </div>

            </li>



    </ul>

    </div>


</section>

<section className="our_features">

    <div className="container">

        <h1>Our Website Features & Capabilities</h1>

        <div className = "fea_list">
        <StaticImage
                            src="../images/laptoptab.png"
                            quality = "100"
                          width = "720"
                            alt="A Gatsby astronaut"
                            placeholder="tracedSVG"
                            />

        <ul>
        <li><i class="fa fa-check"></i>MOBILE-FRIENDLY, RESPONSIVE DESIGN</li>
        <li><i class="fa fa-check"></i>BUILT WITH SEO</li>
        <li><i class="fa fa-check"></i>EVERYTHING IS DONE IN-HOUSE</li>
        <li><i class="fa fa-check"></i>FAST PAGE SPEED</li>
        <li><i class="fa fa-check"></i>EASY TO MAINTAIN AFTER LAUNCH</li>
        <li><i class="fa fa-check"></i>ADA COMPLIANT</li>
        <li><i class="fa fa-check"></i>INTEGRATED ANALYTICS & GOALS</li>
        <li><i class="fa fa-check"></i>TOP TIER WEBSITE HOSTING</li>
        <li><i class="fa fa-check"></i>E-COMMERCE INTEGRATION</li>
        <li><i class="fa fa-check"></i>CUSTOM DEVELOPMENT</li>
        <li><i class="fa fa-check"></i>UNIQUE CREATIVE DESIGNS</li>
        <li><i class="fa fa-check"></i>DELIVERED ON-TIME & ON-BUDGET!</li>


        </ul>

       
        </div>
        <Link  className = "video_schedule" to   = "https://api.leadconnectorhq.com/widget/appointment/generalcalendar-estorecompany/discovery-call-for-virtual-assistant" ><i class="fa fa-video-camera" aria-hidden="true"></i> schedule a demo</Link>
         

    </div>



</section>




<Testimonials/>
<Faqs/>
<section className = "get_touch va">

    <div className ="container">

      <h5>get in touch</h5>
      <h1>You’re missing the Virtual Assistant magic. Let’s unlock it!</h1>
      <Link to ="https://api.leadconnectorhq.com/widget/appointment/generalcalendar-estorecompany/discovery-call-for-virtual-assistant" className = "a_btn">get started </Link>
    </div>


</section>

        </Layout>


 )

}


export default RealEstate